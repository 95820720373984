import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import ManagersSelectContainer from '../../../components/ManagersSelectContainer/ManagersSelectContainer';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import moment, { ISO_8601 } from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

class FilterQuotes extends React.PureComponent {
  constructor(props) {
    super(props);
    // debugger
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.changeProvider = this.changeProvider.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.changeOfficeCountry = this.changeOfficeCountry.bind(this);
    this.handleAuthorTypeChange = this.handleAuthorTypeChange.bind(this);
    this.handleHideAfterDays2 = this.handleHideAfterDays2.bind(this);
    this.handleIsActiveOnSite = this.handleIsActiveOnSite.bind(this);
    this.handleNotifyAuthorByTelegram =
      this.handleNotifyAuthorByTelegram.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);

    const filter = this.loadFilter();
    this.state = filter || {
      author_type: null,
      endDate: '',
      hide_after_days_2: null,
      is_active_on_site: null,
      managers: [],
      notify_author_by_telegram: null,
      offices: [],
      offices_city: null,
      offices_country: null,
      provider: '',
      startDate: '',
    };
  }

  componentDidMount() {
    // Получаем сохраненные значения фильтров из Redux
    const savedFilters = this.props.savedFilters;

    // Если есть сохраненные фильтры, применяем их
    if (savedFilters) {
      // Выполняем фильтрацию с сохраненными значениями
      this.props.onFilter(savedFilters);
    }
  }

  changeOffice(offices) {
    this.setState({ offices });
  }

  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  changeOfficeCountry(offices_country) {
    this.setState({ offices_country });
  }

  handleAuthorTypeChange(author_type) {
    this.setState({ author_type });
  }

  handleHideAfterDays2(hide_after_days_2) {
    this.setState({ hide_after_days_2 });
  }
  handleIsActiveOnSite(is_active_on_site) {
    this.setState({ is_active_on_site });
  }
  handleNotifyAuthorByTelegram(notify_author_by_telegram) {
    this.setState({ notify_author_by_telegram });
  }

  handleManagersOnChange(managers) {
    this.setState({ managers });
  }

  handleReset(value) {
    this.resetFilter();
    this.setState(
      {
        author_type: null,
        endDate: '',
        hide_after_days_2: null,
        is_active_on_site: null,
        managers: [],
        notify_author_by_telegram: null,
        offices: [],
        offices_city: null,
        offices_country: null,
        provider: '',
        startDate: '',
      },
      () => this.handleSubmit()
    );
  }

  _get_valid_serialize_state(data) {
    const fields = [
      'startDate',
      'endDate',
      'provider',
      'hide_after_days_2',
      'is_active_on_site',
      'notify_author_by_telegram',
      'author_type',
      'managers',
      'offices_city',
      'offices_country',
    ];
    const validated_data = {};

    for (let field in data) {
      if (~fields.indexOf(field)) {
        if (field == 'startDate' || field == 'endDate') {
          validated_data[field] = data[field] ? data[field] : '';
        } else {
          validated_data[field] = data[field];
        }
      }
    }
    return validated_data;
  }

  saveFilter(state) {
    const valid_state = this._get_valid_serialize_state(state);
    localStorage.setItem('quotes_filter', JSON.stringify(valid_state));
  }

  resetFilter() {
    localStorage.removeItem('quotes_filter');
  }

  loadFilter() {
    if (localStorage.getItem('quotes_filter')) {
      const data = JSON.parse(localStorage.getItem('quotes_filter'));
      return this._get_valid_serialize_state(data);
    }
  }

  handleSubmit() {
    this.saveFilter(this.state);
    this.props.onFilter(this.state);
  }

  changeProvider(provider) {
    this.setState({ provider });
  }
  startDateChange(startDate) {
    this.setState({ startDate });
  }

  endDateChange(endDate) {
    this.setState({ endDate });
  }

  render() {
    const userRole = getUserRole(),
      AUTHOR_TYPE_OPTIONS = [
        { value: 'normal', label: 'Без типа' },
        { value: 'franch', label: 'Франчайзи' },
        { value: 'freelancer', label: 'Фрилансер' },
        { value: 'subagent', label: 'Субагент' },
      ],
      IS_ACTIVE_ON_SITE_OPTIONS = [
        { value: false, label: 'Да' },
        { value: true, label: 'Нет' },
      ],
      NOTIFY_AUTHOR_BY_TELEGRAM_OPTIONS = [
        { value: true, label: 'Да' },
        { value: false, label: 'Нет' },
      ];
    return (
      <div className="card card--search-bg filter-container">
        <div className="card-header">
          <strong>Мои геоподборки</strong>
          <Link
            style={{ color: '#fff', float: 'right' }}
            target="_blank"
            rel="noopener noreferrer"
            to="/docs/geoclient.pdf"
          >
            <i className="fa fa-file-pdf-o"></i> Скачать файл инструкции по
            геоподборкам CRM
          </Link>
        </div>
        <div className="card-body">
          <form action="" method="post">
            <OfficeSelectContainer
              changeCity={this.changeOfficeCity}
              changeCountry={this.changeOfficeCountry}
              changeOffice={this.changeOffice}
              multi_office={true}
              selected_city={this.state.offices_city}
              selected_country={this.state.offices_country}
              selected_office={this.state.offices}
            >
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Телеграм-уведомления
                </label>
                <Select
                  id="notify_author_by_telegram-selector"
                  placeholder="Телеграм-уведомления"
                  name="notify_author_by_telegram"
                  value={this.state.notify_author_by_telegram}
                  isSearchable={false}
                  onChange={this.handleNotifyAuthorByTelegram}
                  options={NOTIFY_AUTHOR_BY_TELEGRAM_OPTIONS}
                  isClearable
                />
              </div>
            </OfficeSelectContainer>
            <div className="filter-row">
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <>
                  <div className="filter-field">
                    <label htmlFor="" className="col-form-label">
                      Менеджер
                    </label>
                    <ManagersSelectContainer
                      manager_selected={this.state.managers}
                      changeManager={this.handleManagersOnChange}
                      isMulti={true}
                    />
                  </div>
                  <div className="filter-field">
                    <label htmlFor="" className="col-form-label">
                      Тип менеджера
                    </label>
                    <Select
                      name="author_type"
                      onChange={this.handleAuthorTypeChange}
                      options={AUTHOR_TYPE_OPTIONS}
                      placeholder="Тип менеджера"
                      isMulti={false}
                      isSearchable={false}
                      value={this.state.author_type}
                      isClearable
                    />
                  </div>
                </>
              )}
              <div className="filter-field date-from">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата от
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.startDateChange}
                  className="d-block"
                  placeholderText="ОТ даты создания"
                  isClearable={this.state.startDate ? true : false}
                />
              </div>
              <div className="filter-field date-to">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата до
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.endDateChange}
                  placeholderText="ДО даты создания"
                  isClearable={this.state.endDate ? true : false}
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Скрыта на сайте
                </label>
                <Select
                  id="is_active_on_site-selector"
                  placeholder="Скрыта на сайте"
                  name="is_active_on_site"
                  value={this.state.is_active_on_site}
                  isSearchable={false}
                  onChange={this.handleIsActiveOnSite}
                  options={IS_ACTIVE_ON_SITE_OPTIONS}
                  isClearable
                />
              </div>
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Скрыть через 2 дня
                </label>
                <Select
                  id="notify_author_by_telegram-selector"
                  placeholder="Скрыть через 2 дня"
                  name="hide_after_days_2"
                  value={this.state.hide_after_days_2}
                  isSearchable={false}
                  onChange={this.handleHideAfterDays2}
                  options={IS_ACTIVE_ON_SITE_OPTIONS}
                  isClearable
                />
              </div>
            </div>
          </form>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-sm btn-primary mr-2"
              onClick={this.handleSubmit}
            >
              Найти
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              Сбросить
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterQuotes;
