import React, { Component, useEffect, useState } from 'react';
import BackButton from '../../../components/BackButton';
import Select from 'react-select';
import APIClient, {
  POSITIVE_ACTION_STATUSES,
  TouristFpAPI,
} from '../../../api/Client';
import { Alert, Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';
import FieldErrorViewer from '../../Passports/components/FieldErrorViewer';
import DatePicker from 'react-datepicker';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import EditInputPhone from '../../../components/EditInputPhone/EditInputPhone';
import ProgressBar from '../../../components/ProgressBar';
import { useParams } from 'react-router-dom';

const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
const FRONTEND_DATE_FORMAT = 'dd.MM.yyyy';
const TIMEOUT = 2000;

function TouristFpEdit() {
  const { touristId } = useParams();
  const [touristInfo, setTouristInfo] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isErrorSave, setErrorSave] = useState(false);
  const [isSuccessSave, setSuccessSave] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  const [isTagsDownloaded, setTagsDownloaded] = useState(false);
  const [isTouristInfoDownloaded, setTouristInfoDownloaded] = useState(false);

  useEffect(() => {
    getInfo();
    fetchTags();
  }, []);

  function getInfo() {
    console.log('getting info');
    setLoading(true);
    TouristFpAPI.fetchJSON(`${touristId}/`)
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        setTouristInfo(r);
        setLoading(false);
      });
  }

  function fetchTags() {
    setLoading(true);
    APIClient.fetchJSON('/tags/full_list/')
      .then((r) => r.json())
      .then((r) => {
        setTags(r || []);
        setTagsDownloaded(true);
        setLoading(false);
      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setTouristInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleFormAfterEnter();
  }

  function handleTagsOnChange(tag) {
    console.log(tag, tags);
    const tagValuesArr = tag.map((item) => item.value);
    setTouristInfo((prevProps) => ({ ...prevProps, tag: tagValuesArr }));
    handleFormAfterEnter();
  }

  function handleDate(born) {
    setTouristInfo((prevState) => ({
      ...prevState,
      born,
    }));
    handleFormAfterEnter();
  }

  function handleFormAfterEnter() {
    setFormChanged(true);
    setSuccessSave(false);
    setErrorSave(false);
    setErrorMessages({});
  }

  function handleFormAfterSubmit() {
    setFormChanged(false);
    setSuccessSave(true);
    setErrorSave(false);
    setErrorMessages({});
  }

  function handleFormAfterError(err) {
    setFormChanged(false);
    setSuccessSave(false);
    setErrorSave(true);
    setErrorMessages(err);
  }

  function handleSave() {
    const tagValuesArr = tag.map((item) => item.value);
    setTouristInfo((prevProps) => ({ ...prevProps, tag: tagValuesArr }));
    TouristFpAPI.modify(touristId, touristInfo)
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        handleFormAfterSubmit();
        setTimeout(() => setSuccessSave(false), TIMEOUT);
      })
      .catch((err) => handleFormAfterError(err));
  }

  const {
    office = 0,
    full_name = '',
    full_name_eng = '',
    born = '',
    phone = [],
    email = [],
    address = '',
    registration = '',
    comment = '',
    errors = '',
    tag,
  } = touristInfo;
  const isDisabled = !isTouristInfoDownloaded && !isTagsDownloaded;
  const TAGS_OPTIONS = tags.map((tag) => {
    return { value: `${tag.id}`, label: tag.name };
  });

  return (
    <div className="animated fadeIn filter filter--green">
      <div className="row">
        <div className="col-sm-8 col-md-8">
          <div className="card card-accent-primary">
            {!isLoading && touristInfo ? (
              <div className="card-body">
                <div className="form-group row">
                  <label className="text-right col-md-2 col-form-label">
                    Офис:
                  </label>
                  <div className="col-md-8">
                    <OfficeSelectContainer
                      disabledSelect={true}
                      officeId={office}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="full-name-input"
                  >
                    ФИО:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="full-name-input"
                      name="full_name"
                      value={full_name}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="full_name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="full-name-eng-input"
                  >
                    ФИО[ENG]:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="full-name-eng-input"
                      name="full_name_eng"
                      value={full_name_eng}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="full_name_eng"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="address-input"
                  >
                    Адрес:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="address-input"
                      name="address"
                      value={address}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="address"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="registration-input"
                  >
                    Регистрация:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="registration-input"
                      name="registration"
                      value={registration}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="registration"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="text-right col-md-2 col-form-label">
                    Дата рождения:
                  </label>
                  <div className="col-md-8">
                    <DatePicker
                      className="form-control-label"
                      dateFormat={FRONTEND_DATE_FORMAT}
                      selected={
                        born ? moment(born, 'YYYY-MM-DD').format() : undefined
                      }
                      onChange={handleDate}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="born"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="text-right col-md-2 col-form-label">
                    Телефон:
                  </label>
                  <EditInputPhone
                    phones={phone}
                    onChange={(phone) => {
                      setTouristInfo((prevState) => ({
                        ...prevState,
                        phone,
                      }));
                      handleFormAfterEnter();
                    }}
                    errors={
                      errors && errors.phone !== undefined ? errors.phone : null
                    }
                  />
                </div>

                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="email-input"
                  >
                    Эл.почта:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="email"
                      id="email-input"
                      name="email"
                      value={email[0]?.value || ''}
                      onChange={(e) => {
                        const { value } = e.target;
                        setTouristInfo((prevState) => ({
                          ...prevState,
                          email: [{ value }],
                        }));
                        handleFormAfterEnter();
                      }}
                      disabled={isDisabled}
                    />
                    {typeof errorMessages === 'object' &&
                    !!errorMessages &&
                    Object.keys(errorMessages).length &&
                    !!errorMessages.email
                      ? errorMessages.email.map((i, n) => {
                          if (!i.value || !Array.isArray(i.value)) {
                            return null;
                          }
                          return (
                            <div key={`error-${n}`}>
                              {i.value.map((j, m) => (
                                <div
                                  className="invalid-feedback"
                                  key={`error-${n}-${m}`}
                                >
                                  {j}
                                </div>
                              ))}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="tourist-tags-select"
                  >
                    Метки:
                  </label>
                  <div className="col-md-8">
                    <Select
                      id="tourist-tags-select"
                      className="mb-3"
                      name="tags"
                      placeholder="Выберите из спиcка..."
                      isMulti={true}
                      closeOnSelect={true}
                      value={tag}
                      onChange={handleTagsOnChange}
                      options={TAGS_OPTIONS}
                      disabled={isDisabled}
                    />
                  </div>
                  <FieldErrorViewer errorMessages={errorMessages} field="tag" />
                </div>
                <div className="form-group row">
                  <label
                    className="text-right col-md-2 col-form-label"
                    htmlFor="tourist-tags-select"
                  >
                    Комментарий:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="comment-input"
                      name="comment"
                      value={comment}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="full_name"
                    />
                  </div>
                  <FieldErrorViewer errorMessages={errorMessages} field="tag" />
                </div>
                <BackButton />
                <Button
                  color={isFormChanged ? 'success' : 'secondary'}
                  disabled={!isFormChanged || isDisabled}
                  onClick={handleSave}
                >
                  <i className="fa fa-dot-circle-o"> </i> Сохранить
                </Button>
              </div>
            ) : (
              <div
                className="card-body"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '722px',
                }}
              >
                <ProgressBar />
              </div>
            )}
            <div className="card-footer">
              {isErrorSave ? (
                <Alert color="warning">
                  <strong>Внимание</strong>ошибка
                </Alert>
              ) : null}
              {isSuccessSave ? (
                <Alert color="success">
                  <strong>Данные туриста</strong> успешно сохранены
                </Alert>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TouristFpEdit;
