import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import FilterLeads from '../Components/FilterLeads';
import { LeadAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import * as actions from '../../actions/actions';
import LeadsTable from '../../views/LeadsTable';
import { initPageNumber, changePageNumber } from '../../lib/url-search-params';
import ProgressBar from '../../components/ProgressBar';

const PAGE_NAME = 'lead_page';

class Leads extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.deleteLeads = this.deleteLeads.bind(this);
    this.toggleItem = this.toggleItem.bind(this);

    this.state = {
      items: [],
      hasNext: false,
      hasPrev: false,
      pageNumber: initPageNumber(PAGE_NAME),
      count: 0,
      filters: null,
      errors: {},
      isSuccess: false,
      selected: [],
      isLoading: false,
    };
  }

  handlePageChange(data) {
    const page = data.selected + 1;
    this.setState(
      {
        ...this.state,
        pageNumber: page,
        errors: null,
        isSuccess: false,
      },
      () => this.fetchItems(page)
    );
  }

  filterItems(st) {
    let result = {};

    if (st.startDate) {
      result.created__gte = moment(st.startDate).format('DD.MM.YYYY');
    }
    if (st.endDate) {
      result.created__lte = moment(st.endDate).format('DD.MM.YYYY');
    }
    if (st.city) {
      result.city = st.city;
    }

    if (st.agencyCitySelected) {
      result.office__city = st.agencyCitySelected.id;
    }
    if (st.agencyCountrySelected) {
      result.office__country = st.agencyCountrySelected.id;
    }

    if (st.agencySelectedOffice && st.agencySelectedOffice.length !== 0) {
      result.office_id__in = st.agencySelectedOffice
        .map((office) => office.id)
        .join();
    }

    if (st.source) {
      result.source = st.source.map(s => s.value);
    }
    if (st.status) {
      let statusList = st.status.map(s => s.value);
      if (~statusList.indexOf('reserved')) {
        statusList.push('accepted');
        statusList.push('flight_out');
      }
      result.status = statusList;
    }
    if (st.destination_country) {
      result.destination_country = st.destination_country;
    }

    if (st.managers) {
      result.managers = st.managers.map((m) => m.value).join();
    }

    if (result) {
      const page = 1;
      this.setState(
        {
          ...this.state,
          filters: result,
          errors: null,
          isSuccess: false,
          selected: [],
          pageNumber: page,
        },
        () => this.fetchItems(page)
      );
    }
  }

  fetchItems(page = 1) {
    let status;
    const { filters } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    LeadAPI.fetchList(page, filters)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          if (result.count === 0) {
            swal('заявок не найдено, пожалуйста измените критерии поиска');
          }
          changePageNumber(PAGE_NAME, page);
          let leads_data = result.results || [];
          this.props.dispatch(actions.requestLeadsByStateLoaded(leads_data));
          this.setState({
            ...this.state,
            items: result.results || [],
            hasNext: result.next != null,
            hasPrev: result.previous != null,
            count: result.count,
            isLoading: false,
          });
        }
      });
  }

  deleteLeads() {
    let status;
    const { pageNumber, selected } = this.state;

    LeadAPI.deleteLeads(selected)
      .then((r) => {
        status = r.status;
        if (status === 400) {
          return r.json();
        }
        return {};
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          this.setState(
            {
              isSuccess: true,
              selected: [],
              errors: null,
            },
            () => this.fetchItems(pageNumber)
          );
        } else if (status === 400) {
          this.setState({
            errors: r,
            isSuccess: false,
          });
        }
      });
  }

  toggleItem(item) {
    let { selected } = this.state;

    if (selected.includes(item)) {
      this.setState({
        selected: selected.filter((pk) => pk !== item),
        isSuccess: false,
        errors: null,
      });
    } else {
      this.setState((prevState) => {
        const selected = [...prevState.selected];
        selected.push(item);
        return {
          selected: selected,
          isSuccess: false,
          errors: null,
        };
      });
    }
  }

  componentDidMount() {
    const { pageNumber } = this.state;
    this.fetchItems(pageNumber);
  }

  render() {
    const leads = this.props.leads.data || [];
    const {
      filters,
      count,
      errors,
      isSuccess,
      selected,
      pageNumber,
      isLoading,
    } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="filter filter--orange">
          <FilterLeads name="" onFilter={this.filterItems} />
        </div>
        {isLoading ? (
          <ProgressBar />
        ) : !leads.length ? (
          <div className="row animated fadeIn">
            <div className="col-md-12 col-md-offset-6 text-center">
              <p>Заявок с сайта не найдено</p>
            </div>
          </div>
        ) : (
          <LeadsTable
            isShowActions={filters}
            count={count}
            items={leads}
            filters={filters ? filters : []}
            handleRefresh={this.fetchItems}
            handlePageChange={this.handlePageChange}
            handleDeleteLeads={this.deleteLeads}
            toggleItem={this.toggleItem}
            errors={errors}
            isSuccess={isSuccess}
            selected={selected}
            initialPage={pageNumber - 1}
            forcePage={pageNumber - 1}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return { leads: store.leads };
};

export default connect(mapStateToProps)(Leads);
