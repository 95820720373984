import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';

import ManagersSelectContainer from '../../../components/ManagersSelectContainer/ManagersSelectContainer';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';

export const STATUS_DESCRIPTION_MAP = {
  new: 'Новая',
  work: 'Открыл',
  reserved: 'Покупка',
  cancelled: 'Аннулирована',
  call: 'Звонок',
  meet: 'Встреча',
  tours: 'Отправлена подборка',
  client_cancel: 'Турист отказался',
  we_cancel: 'Мы отказали',
  deffered: 'Отложен',
};

export const SOURCE_OPTIONS = [
  {
    label: 'Сайт',
    options: [
      { label: 'Горящая заявка', value: 'geo_site_form_hot' },
      { label: 'Чат', value: 'geo_site_chat' },
      { label: 'Геоподборка', value: 'geo_site_quote' },
      { label: 'Агентский поисковик', value: 'agency_tour' },
      { label: 'Экскурсионный тур', value: 'cabinet_excursion_tour' },
    ],
  },
  {
    label: 'Кабинет',
    options: [
      { label: 'ЛК (офис)', value: 'cabinet' },
      { label: 'ЛК (покупка)', value: 'cabinet_online' },
    ],
  },
];

export const SOURCE_OPTIONS_NAMES = [
  { label: 'Горящая заявка', value: 'geo_site_form_hot' },
  { label: 'Чат', value: 'geo_site_chat' },
  { label: 'Геоподборка', value: 'geo_site_quote' },
  { label: 'Агентский поисковик', value: 'agency_tour' },
  { label: 'Экскурсионный тур', value: 'cabinet_excursion_tour' },
  { label: 'ЛК (офис)', value: 'cabinet' },
  { label: 'ЛК (покупка)', value: 'cabinet_online' },
];

const sourceOptions = SOURCE_OPTIONS;

const statusOptions = Object.keys(STATUS_DESCRIPTION_MAP).map((k) => ({
  label: STATUS_DESCRIPTION_MAP[k],
  value: k,
}));

class FilterLeads extends Component {
  constructor(props) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.destinationCountryChange = this.destinationCountryChange.bind(this);
    this.changeManagers = this.changeManagers.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeAngecyCity = this.changeAngecyCity.bind(this);
    this.changeAngecyCountry = this.changeAngecyCountry.bind(this);
    this.changeSource = this.changeSource.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeWasTransferred = this.changeWasTransferred.bind(this);

    moment.locale('ru');

    this.state = {
      office: '',
      managers: [],
      startDate: '',
      endDate: '',
      city: '',
      agencyCitySelected: null,
      agencyCountrySelected: null,
      agencySelectedOffice: [],
      destination_country: '',
      source: null,
      status: null,
      price: {
        min: 10000,
        max: 500000,
      },
      was_transferred: null,
    };
  }

  changeCity(v) {
    // город вылета
    this.setState({ city: v.target.value });
  }

  changeAngecyCity(agencyCitySelected) {
    this.setState({ agencyCitySelected });
  }

  changeAngecyCountry(agencyCountrySelected) {
    this.setState({ agencyCountrySelected });
  }

  changeOffice(agencySelectedOffice) {
    this.setState({ agencySelectedOffice });
  }

  changeManagers(managers) {
    this.setState({ managers });
  }

  changeSource(source) {
    this.setState({ source });
  }

  changeStatus(status) {
    this.setState({ status });
  }

  changeWasTransferred(was_transferred) {
    this.setState({ was_transferred });
  }

  handleReset() {
    this.setState(
      {
        office: '',
        managers: [],
        agencyCitySelected: null,
        agencyCountrySelected: null,
        agencySelectedOffice: [],
        startDate: '',
        endDate: '',
        city: '',
        destination_country: '',
        source: null,
        status: null,
        was_transferred: null,
      },
      this.handleSubmit
    );
  }

  handleSubmit() {
    if (
      this.state.agencySelectedOffice === null ||
      this.state.agencySelectedOffice.length === 0
    ) {
      this.props.onFilter(this.state);
    } else {
      this.props.onFilter(this.state);
    }
  }

  startDateChange(startDate) {
    this.setState({ startDate });
  }

  endDateChange(endDate) {
    this.setState({ endDate });
  }

  destinationCountryChange(e) {
    this.setState({ destination_country: e.target.value });
  }

  render() {
    const WAS_TRANSFERRED_OPTIONS = [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ];
    return (
      <div className="card card--search-bg filter-container">
        <div className="card-header">
          <strong>Заявки поступившие с сайта и их статус</strong>
        </div>
        <div className="card-body">
          <form className="" action="" method="post">
            <div className="filter-row">
              <div className="filter-field filter__source">
                <Select
                  isMulti={true}
                  joinValues={true}
                  simpleValue={true}
                  onChange={this.changeSource}
                  options={sourceOptions}
                  placeholder="Источник"
                  value={this.state.source}
                />
              </div>
              <div className="filter-field date-from">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата от
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.startDateChange}
                  className="d-block"
                  isClearable
                  placeholderText="Дата создания заявки ОТ"
                />
              </div>
              <div className="filter-field date-to">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата до
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.endDateChange}
                  placeholderText="Дата создания заявки ДО"
                  isClearable
                />
              </div>
              <div className="filter-field filter__type">
                <Select
                  onChange={this.changeWasTransferred}
                  options={WAS_TRANSFERRED_OPTIONS}
                  placeholder="Был перевод"
                  simpleValue={true}
                  value={this.state.was_transferred}
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field departure">
                <label htmlFor="leadCity" className="col-form-label">
                  Город вылета
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadCity"
                  placeholder="Город вылета"
                  value={this.state.city}
                  onChange={this.changeCity}
                />
              </div>
              <div className="filter-field country">
                <label
                  htmlFor="leadDestinationCountry"
                  className="col-form-label"
                >
                  Страна назначения
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadDestinationCountry"
                  placeholder="Страна назначения"
                  value={this.state.destination_country}
                  onChange={this.destinationCountryChange}
                />
              </div>
              <div className="filter-field filter__type">
                <Select
                  isMulti={true}
                  joinValues={true}
                  simpleValue={true}
                  onChange={this.changeStatus}
                  options={statusOptions}
                  placeholder="Статус"
                  value={this.state.status}
                />
              </div>
              <div className="filter-field filter__type">
                <ManagersSelectContainer
                  manager_selected={this.state.managers}
                  changeManager={this.changeManagers}
                  isMulti={true}
                />
              </div>
            </div>
            <OfficeSelectContainer
              changeCity={this.changeAngecyCity}
              changeCountry={this.changeAngecyCountry}
              changeOffice={this.changeOffice}
              multi_office={true}
              selected_city={this.state.agencyCitySelected}
              selected_country={this.state.agencyCountrySelected}
              selected_office={this.state.agencySelectedOffice}
            />
          </form>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
            >
              Найти
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              Сбросить
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterLeads;
